* {
    margin:0;
    padding:0;
}

html,
body,
#root {
    height: 100% !important;
    margin: 0 !important;
}

.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* HEADER */
header {
    display: flex;
    flex-direction: row;
    height: 50px;
}

button:focus {
    outline: 0;
}

nav {
    display: flex;
    flex-direction: row;
    width: 100vw;
}

nav button {
    width: 50px;
    height: 50px;
    border: none;
    background-color: inherit;
}

nav i {
    color: black;
}

nav button:hover,
.filter-button:hover {
    background-color: rgba(20, 30, 32, 0.1);
    transition: opacity 0.1s ease;
    transition-property: opacity;
    transition-duration: 0.1s;
    transition-timing-function: ease;
    transition-delay: 0s;
}

nav button i {
    margin: 0px !important;
}

.search-wrapper {
    flex-grow: 1;
    padding: 2px 10px;
    border-left: 1px solid rgba(34, 36, 38, 0.1);
    border-right: 1px solid rgba(34, 36, 38, 0.1);
}

.search-wrapper .ui.category.fluid.search {
    width: 100%;
    height: 100%;
}

.search-wrapper .input {
    width: 100%;
}

#searchInput {
    height: 46px;
    width: 100%;
    font-size: 1.4em;
}

@media only screen and (max-width: 640px) {
    .results.transition {
        max-height: 48vh;
        overflow-y: scroll;

        position: fixed !important;
        left: 1vw !important;
        top: 50px !important;
        width: 98vw !important;
        max-width: 98vw !important;
    }
}

@media only screen and (min-width: 641px) {
    .results.transition {
        max-height: 67vh;
        overflow-y: scroll;
        width: unset !important;
        position: inherit !important;
    }
}

.customResult {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.resultTitle {
    font-size: 1.2em;
}

.resultDescription,
.resultDescription i {
    font-size: 0.92857143em !important;
    color: rgba(0, 0, 0, 0.6) !important;
}

.colorCircle {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.15);
}

/* Filter Dropdown */
.filter-button {
    width: 50px;
}

.filter-button .ui.multiple.dropdown {
    width: 100%;
    height: 100%;
}

.filter-button .text {
    position: absolute !important;
}

.filter-button .filter.icon {
    padding-left: 11px;
    padding-top: 14px;
}

.ui.dropdown .menu {
    left: unset;
    right: 0px;
}

.ui.dropdown .scrolling.menu {
    max-height: 60vh !important;
}

.menu.transition.visible {
    min-width: 220px;
}

.scrolling.menu.transition .item {
    height: 35px;
}

.filterHeader i {
    margin-right: 15px;
}

/* End Filter Dropdown */
/* END HEADER */

/* MAIN */
main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: white;
}

main.dark h1,
main.dark h2,
main.dark h3,
main.dark h4 {
    color: black !important;
}

main.dark .delta:hover {
    background: black;
    color: white !important;
}

main.dark .best-match h4,
main.dark .good-match h4 {
    background: black;
    color: white !important;
}

main.dark .hr:not(.transparent),
main.dark .arrow {
    border-color: black;
}

/* CURRENT COLOR */
.current-color {
    display: flex;
    flex-grow: 0.3;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.current-color div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.current-color div:first-child,
.current-color div:last-child {
    width: 20%;
}

.current-color div {
    width: 60%;
    text-align: center;
    padding: 0px 5px;
}
/* END CURRENT COLOR */

/* SIMILAR COLORS */
.similar-colors-wrapper {
    display: flex;
    flex-grow: 0.7;
    flex-direction: row;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.similar-color div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.similar-color .color-header,
.similar-color .delta-content {
    height: 30%;
}

.similar-color .color-header {
    justify-content: flex-start;
    padding: 0px 5px;
    margin-top: 60px;
}

.similar-color .delta-content {
    justify-content: space-evenly;
}

.similar-color .color-header h4 {
    height: initial;
    margin-bottom: 10px !important;
}

.similar-color {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    min-width: 160px;
}

.delta {
    padding: 8px;
    border-radius: 25px;
}

.delta:hover {
    background: white;
    opacity: 0.7;
    cursor: pointer;
    color: black;
    transition: 0.3s ease-in-out;
}

.match-status {
    justify-content: center;
    height: 35px;
}

.match-status h4 {
    background: white;
    color: black !important;
    padding: 8px;
    border-radius: 25px;
}

.best-match, .good-match {
    flex-direction: row !important;
    justify-content: space-between;
}

.hr {
    border-bottom: 3px solid white;
    margin: 0px;
    width: 100%;
}

.best-match .hr,
.good-match .hr {
    width: 15%;
}

.arrow-container {
    width: 15% !important;
    display: flex !important;
    flex-direction: row !important;
}

.arrow-container .hr {
    width: 100% !important;
}

.arrow {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    margin: -12px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

.transparent {
    background-color: transparent;
    border-color: transparent;
}

/* END SIMILAR COLORS */

/* END MAIN */

h1,
h2,
h3,
h4,
h5 {
    opacity: 0.7 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

h3,
h4,
h5 {
    font-weight: 400;
}

i.icon {
    font-size: 1.4em;
}

/* SNACKBAR */
#snackbar {
    visibility: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    position: fixed;
    z-index: 1;
    bottom: 30px;
    font-size: 17px;
}

#snackbar div {
    max-width: 95vw;
    width: max-content;
    background-color: #333;
    color: white;
    border-radius: 2px;
    padding: 16px;
}

#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}
